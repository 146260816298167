import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";

class Footer extends Component {
  render() {
    return (
      <footer className="mt-auto bg-darker">
        <p className="m-3 text-light text-center">
          made with <a href="https://reactjs.org/">React</a> and hosted on{" "}
          <a href="https://render.com/">Render</a>
        </p>
      </footer>
    );
  }
}

export default Footer;
