import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";

class NavBar extends Component {
  render() {
    return (
      <Router>
        <nav className="navbar navbar-expand-sm bg-darker navbar-dark justify-content-center sticky-top">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                .home
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/code" className="nav-link">
                .code
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/sound" className="nav-link">
                .sound
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/kittens" className="nav-link">
                .kittens
              </Link>
            </li> */}
          </ul>
        </nav>

        <div className="d-flex flex-column pt-3 align-items-center flex-grow-1">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            {/* <Route path="/code">
              <Code />
            </Route> */}
            <Route path="/sound">
              <Sound />
            </Route>
            {/* <Route path="/kittens">
              <Kittens />
            </Route> */}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default NavBar;

function Home() {
  return (
    <p className="text-light text-center">
      music producer and web developer
      <br /> i guess...
    </p>
  );
}

// function Code() {
//   return <h1></h1>;
// }

function Sound() {
  // const testCard = (
  //   <div className="card mb-3" style={{ maxWidth: "740px" }}>
  //     <div className="row no-gutters">
  //       <div className="col-md-4">
  //         <img
  //           src="img/cover-placeholder.jpg"
  //           alt="..."
  //           style={{ maxWidth: "256px" }}
  //         />
  //       </div>
  //       <div class="col-md-8">
  //         <div class="card-body">
  //           <h5 class="card-title">Cyberpunk title</h5>
  //           <p class="card-text">
  //             This is a wider card with supporting text below as a natural
  //             lead-in to additional content. This content is a little bit
  //             longer.
  //           </p>
  //           <p class="card-text">
  //             <small class="text-muted">EP</small>
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return <p className="text-light">under construction...</p>;
}

// function Kittens() {
//   return <h1></h1>;
// }
