import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../App.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.timerId = 0;
  }

  componentDidMount() {
    this.timerId = setInterval(() => this.tick(), 40);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  tick = () => {
    let logo = document.getElementById("logo-container");
    let children = logo.children;
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      if (child.id == "logo") {
        continue;
      }
      logo.removeChild(child);
    }

    let count = 3;
    for (let i = 0; i < count; i++) {
      let glitchBox = document.createElement("div");
      glitchBox.className = "glitchBox";

      // let width = 60 + Math.floor(Math.random() * 40);
      let width = 100;
      glitchBox.style.width = width + "%";
      glitchBox.style.left = Math.floor(Math.random() * (100 - width)) + "%";

      let height = 1 + Math.floor(Math.random() * 3);
      glitchBox.style.top = Math.floor(Math.random() * (100 - height)) + "%";
      glitchBox.style.height = height + "%";
      logo.appendChild(glitchBox);
    }
  };

  render() {
    return (
      <header className="container-fluid bg-darker text-white">
        <div className="d-flex flex-column flex-wrap justify-content-center align-items-center">
          <div id="logo-container" className="w-25 h-25">
            <img
              id="logo"
              src="img/reaven-img.png"
              className="img-fluid p-3"
              alt="logo"
            />
          </div>
          <h4>reaven</h4>
        </div>
      </header>
    );
  }
}

export default Header;
